import React from "react"
import { graphql} from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Manifest from "../components/manifest"
import FeatureLists from "../components/featureLists"
import Subscription from "../components/subscription"
import SEO from "../components/seo"
import { normalizeRawHomepageData } from '../services'

const IndexPage = ({data: { allPrismicHomepage, prismicSeo}}) => {
  const data = normalizeRawHomepageData(allPrismicHomepage)

  return (
    <Layout>
      <SEO 
        title={prismicSeo.data.title.text}
        description={prismicSeo.data.description.text}
        image={prismicSeo.data.image.url}
      />
      <Hero title={data.hero.title}  image={data.hero.image} />
      <Manifest text={data.manifest}/>
    </Layout>
  )


}

export const pageQuery = graphql`
  query {
    prismicSeo {
      data {
        title {
          text
        }
        description {
          text
        }
        image {
          url
        }
      }
    }
    allPrismicHomepage {
      edges {
        node {
          data {
            hero_title {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            manifest_text {
              html
            }
            subscription_text {
              html
            }
            email_text_placeholder {
              text
            }
            email_button_text {
              text
            }
          }
        }
      }
    }
  }
`

export default IndexPage
