import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  blockDivStyled,
  blockWrapperStyled,
  blockItemStyled,
} from "../styles/globalStyle"
import { breakpoint } from "../styles/mixins"
import { variables } from "../styles/variables"

const Hero = ({ title, image }) => {
  return (
    <HeroStyled>
      <HeroWrapperStyled>
        <HeroItemStyled>
          <HeroTitleStyled>{title}</HeroTitleStyled>
        </HeroItemStyled>
        <HeroItemStyledImage>
          <Img fadeIn={false} fluid={image.localFile.childImageSharp.fluid} />
        </HeroItemStyledImage>
      </HeroWrapperStyled>
    </HeroStyled>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
}

Hero.defaultProps = {
  title: "",
  image: {},
}
const HeroStyled = styled.section`
  ${blockDivStyled}
`
const HeroWrapperStyled = styled.div`
  ${blockWrapperStyled}
  padding: 64px 0 24px 0;
  text-align: center;

  ${breakpoint.lg`
    flex-wrap: wrap;
    padding: 96px 0 0;
  `}
`
const HeroItemStyled = styled.div`
  ${blockItemStyled}
  padding: 32px 0 96px 0;
  display: block;
`
const HeroItemStyledImage = styled(HeroItemStyled)`
  padding: 0;
  margin: 0 auto;
  ${breakpoint.md`
    max-width: 75%;
  `}
  ${breakpoint.lg`
    max-width: 55%;
  `}
`
const HeroTitleStyled = styled.h1`
  font-size: ${variables.mediumFontSizeMobile}px;
  line-height: ${variables.lineHeight};
  white-space: pre-line;
  ${breakpoint.md`
    font-size: ${variables.mediumFontSizeTablet}px;
  `}
  ${breakpoint.xl`
    font-size: ${variables.mediumFontSizeDekstop}px;
  `}
`

export default Hero
