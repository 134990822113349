import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { blockDivStyled, blockWrapperStyled, blockItemStyled } from "../styles/globalStyle"
import { breakpoint } from '../styles/mixins';
import { variables } from '../styles/variables'
import parse from 'html-react-parser';

const Manifest = ({text}) => {
  return (
    <ManifestStyled>
      <ManifestWrapperStyled>
        <ManifestItemStyled>
          { parse(text) }
        </ManifestItemStyled>
      </ManifestWrapperStyled>
    </ManifestStyled>
  )
}

Manifest.propTypes = {
  text: PropTypes.string.isRequired,
}

Manifest.defaultProps = {
  text: '',
}

const ManifestStyled = styled.section`
  ${blockDivStyled}
  
`
const ManifestWrapperStyled = styled.div`
  ${blockWrapperStyled}
  padding: 32px 0;
  ${breakpoint.lg`
    padding: 48px 0;
    flex-wrap: nowrap;
  `}
`
const ManifestItemStyled = styled.div`
  ${blockItemStyled}
  display: block;
 
  ${breakpoint.md`
    max-width: 80%;
  `}
  ${breakpoint.lg`
    max-width: 50%;
  `}
  p {
    padding: 16px 0;
    font-size: 21px;
    line-height: ${variables.lineHeight};
    ${breakpoint.md`
      font-size: ${variables.mediumFontSizeTablet}px;
    `}
    ${breakpoint.xl`
      font-size: ${variables.mediumFontSizeDekstop}px;
    `}
  }
  a {
    color: ${variables.primaryColor}
    font-family: ${variables.mediumFamily}
  }
  `

export default Manifest
