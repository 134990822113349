import React from "react"
import PropTypes from "prop-types"
import FeatureList from "../components/featureList"
import styled from "styled-components"
import { blockWrapperStyled } from "../styles/globalStyle"
import { breakpoint } from '../styles/mixins';

const FeatureLists = ({featureLists}) => {
  return (
    <FeatureListsStyled>
      { featureLists.map((list, index) => <FeatureList key={index} featureList={list} />)}
    </FeatureListsStyled>
  )
}

FeatureLists.propTypes = {
  featureLists: PropTypes.arrayOf(PropTypes.shape({
    listContent: PropTypes.string.isRequired,
    listTitle: PropTypes.string.isRequired,
  })).isRequired,
}

FeatureLists.defaultProps = {
  featureLists: [],
}

const FeatureListsStyled = styled.section`

  ${blockWrapperStyled}
  padding: 32px 0;
  align-items: flex-start;

  ${breakpoint.md`
    flex-wrap: nowrap;
  `}  
  ${breakpoint.lg`
    padding: 80px 0;
  `}  
`

export default FeatureLists
