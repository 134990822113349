import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoint } from '../styles/mixins';
import { variables } from '../styles/variables'
const NewsletterForm = ({ onSubmit, email, onChange, buttonText, inputPlaceholder  }) => (
  <NewsletterFormStyled onSubmit={onSubmit}>
    <NewsletterFormInput type="text" placeholder={inputPlaceholder} value={email} onChange={onChange} />
    <NewsletterFormButton type="submit" value={buttonText} />
  </NewsletterFormStyled>
)

NewsletterForm.propTypes = {
  onSubmit: PropTypes.any,
  email: PropTypes.string,
  buttonText: PropTypes.string,
  onChange: PropTypes.any,
  inputPlaceholder: PropTypes.string
}

NewsletterForm.defaultProps = {
  onSubmit: () => {},
  email: '',
  buttonText: '',
  onChange: () => {},
  inputPlaceholder: ''
}

const NewsletterFormStyled = styled.form`
  width: 100%;
  display: inline-flex;
  padding: 32px 0;
  flex-wrap: wrap;
  flex: 0;
`

const NewsletterFormInput = styled.input`
  border-radius: 0;
  border: 0;
  flex: 1 1 100%;
  -webkit-appearance: textfield;
  -webkit-padding-start:0;
  font-size: ${variables.mediumFontSizeMobile}px;
  color: ${variables.primaryColor};
  margin-bottom: 32px;
  border-bottom: 3px solid rgba(0,0,0,0.1);
  transition: border 0.2s ease-out;
  &:focus {
    border-bottom: 3px solid ${variables.primaryColor};
  }
  &::placeholder {
    color: ${variables.secondaryColor};
  }
  ${breakpoint.md`
    flex: 0 0 calc(40% - 16px);
    margin-right: 16px;
    margin-bottom: 0;
    font-size: 24px
  `}
  ${breakpoint.xl`
    font-size: ${variables.mediumFontSizeDekstop}px
  `}
`
const NewsletterFormButton = styled.input`
  border-radius: 0;
  width: auto;
  padding: 0;
  -webkit-appearance: none!important;
  flex: 0;
  border: 0;
  background: transparent!important;
  color: ${variables.primaryColor};
  display: inline-block;
  //text-decoration: underline;
  border-bottom: 3px solid ${variables.primaryColor};
  box-shadown: 0;
  font-size: ${variables.mediumFontSizeMobile}px;
  outline: 0;
  text-align: left;
  ${breakpoint.md`
    font-size: ${variables.mediumFontSizeTablet}px
  `}
  ${breakpoint.xl`
    font-size: ${variables.mediumFontSizeDekstop}px
  `}
`
export default NewsletterForm
