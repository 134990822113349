import React from "react"
import PropTypes from "prop-types"
import parse from 'html-react-parser';
import styled from "styled-components"
import { breakpoint } from '../styles/mixins';
import { variables } from '../styles/variables'

const FeatureList = ({featureList: {listTitle, listContent}}) => {
  return (
    <FeatureListStyled>
      <FeatureListTitleStyled>{listTitle}</FeatureListTitleStyled>
      { parse(listContent) }
    </FeatureListStyled>
  )
}

FeatureList.propTypes = {
  featureList: PropTypes.shape({
    listContent: PropTypes.string.isRequired,
    listTitle: PropTypes.string.isRequired,
  }).isRequired
}

FeatureList.defaultProps = {
  featureList: {
    listContent: '',
    listTitle: ''
  },
}

const FeatureListStyled = styled.div`
  flex: 1 1 100%;
  padding: 16px 0;
  ${breakpoint.md`
    padding: 0;
    flex: 1 1 50%;
  `}

  ${breakpoint.lg`
    padding: 0;
  `}
  &:last-of-type {
    opacity: 0.4;
    ${breakpoint.lg`
      padding-bottom: 0;
    `}
  }
  ul {
    li {
      font-size: 18px;
      line-height: 2;
      ${breakpoint.md`
        font-size: 20px;
        line-height: 2;
      `}

      ${breakpoint.lg`
        font-size: 24px;
        line-height: 2;
      `}
    }
  }
`
const FeatureListTitleStyled = styled.h3`
  font-size: ${variables.mediumFontSizeMobile}px;
  line-height: ${variables.lineHeight};
  padding-bottom: 8px;
  ${breakpoint.md`
    font-size: ${variables.mediumFontSizeTablet}px
  `}
  ${breakpoint.xl`
    font-size: ${variables.mediumFontSizeDekstop}px
    line-height: ${variables.lineHeight};
  `}
`


export default FeatureList
