import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import addToMailchimp from "gatsby-plugin-mailchimp"
import NewsletterForm from "./newsletterForm"
import styled from "styled-components"
import ReactGA from "react-ga"

import {
  blockDivStyled,
  blockWrapperStyled,
  blockItemStyled,
} from "../styles/globalStyle"
import { breakpoint } from "../styles/mixins"
import { variables } from "../styles/variables"

const Subscription = ({
  id,
  subscriptionContent: { title, emailButtonText, emailTextPlaceholder },
}) => {
  const [error, setError] = useState(false)
  const [resultMessage, setResultMessage] = useState("")
  const [email, setEmail] = useState("")

  const onChange = event => {
    setEmail(event.target.value)
    setResultMessage("")
  }
  useEffect(() => {
    ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS)
  }, [])
  const onSubmit = event => {
    event.preventDefault()
    addToMailchimp(email)
      .then(data => {
        setError(false)
        setResultMessage(data.msg)
        if (data.result === "error") {
          setError(true)
        } else {
          setEmail("")
          ReactGA.event({
            category: "lead",
            action: "request-access",
          })
        }
      })
      .catch(() => {
        setError(true)
        setResultMessage("Oh no! Something went wrong, please retry! :(")
      })
  }

  return (
    <SubscriptionStyled id={id}>
      <SubscriptionWrapperStyled>
        <SubscriptionItemStyled>
          <SubscriptionTitletyled>{parse(title)}</SubscriptionTitletyled>
          <NewsletterForm
            onSubmit={onSubmit}
            inputPlaceholder={emailTextPlaceholder}
            onChange={onChange}
            email={email}
            buttonText={emailButtonText}
          />
          {!error && resultMessage && <p>{resultMessage}</p>}
          {error && resultMessage && <p>{parse(resultMessage)}</p>}
        </SubscriptionItemStyled>
      </SubscriptionWrapperStyled>
    </SubscriptionStyled>
  )
}

Subscription.propTypes = {
  subscription: PropTypes.shape({
    title: PropTypes.string.isRequired,
    emailButtonText: PropTypes.string.isRequired,
    emailTextPlaceholder: PropTypes.string.isRequired,
  }),
}

Subscription.defaultProps = {
  title: "",
  emailButtonText: "",
  emailTextPlaceholder: "",
}

const SubscriptionStyled = styled.section`
  ${blockDivStyled}
`
const SubscriptionWrapperStyled = styled.div`
  ${blockWrapperStyled}
  padding: 32px 0;
  ${breakpoint.lg`
    padding: 80px 0;
  `}
`
const SubscriptionItemStyled = styled.div`
  ${blockItemStyled}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > p {
    font-size: 14px;
    line-height: 1.6;
    max-width: 500px;
    a {
      color: ${variables.primaryColor};
    }
  }
`

const SubscriptionTitletyled = styled.div`
  p {
    font-size: ${variables.mediumFontSizeMobile}px
    line-height: ${variables.lineHeight}
    ${breakpoint.md`
      font-size: 24px
      font-size: ${variables.mediumFontSizeTablet}px
    `}
    ${breakpoint.xl`
      font-size: ${variables.mediumFontSizeDekstop}px
    `}
  }
`
export default Subscription
